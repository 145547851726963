import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import joseph from '../../assets/images/departments/psy/joseph.png';
import aina from '../../assets/images/departments/psy/aina.png';
import oshodi from '../../assets/images/departments/psy/osohodi.png';
import erinfola from '../../assets/images/departments/psy/erinfola.png';
import fatiregun from '../../assets/images/departments/psy/fatiregun.png';
import umeh from '../../assets/images/departments/psy/umeh.jpg';
import tajudeen from '../../assets/images/departments/psy/taofeek.jpg';
import okeowo from '../../assets/images/departments/psy/okeowo.jpg';
import juliet from '../../assets/images/departments/psy/juliet.jpg';
import sodimu from '../../assets/images/departments/psy/sodimu.png';
import team1 from '../../assets/images/team-1-1.jpg';

const Psychiatry = () => {
  const clinical = [
    {
      name:
        'Out-patient clinics: For new patients and clinic follow-up of old cases In the various clinics listed above',
    },
    {
      name: 'In-patient care for acutely ill patients admitted to Ward E 1.',
    },
    {
      name:
        'Consultation-liaison psychiatric care: Clinical services to patients with psychiatric complications referred from other departments/ units of the hospital',
    },
    {
      name:
        'School Mental Health Services under the auspices of CAMH Unit of the Department to various secondary schools and tertiary institutions in Lagos. ',
    },

    {
      name:
        'Community Mental Health care services at LUTH Comprehensive Health care clinic, Pakoto, Ogun State.',
    },
    {
      name: 'Counselling/ Psychotherapy services',
    },
    {
      name:
        'Psychometric tests services: Intelligence tests, personality tests etc.',
    },
  ];

  const training = [
    {
      name:
        'The department carries out training in Clinical psychiatry, Medical and Clinical Psychology to the following cadres of students: Medical (MBBS) Students,Radiography students,Physiotherapy students,Post-basic Nursing students',
    },
    {
      name:
        'Post-graduate Psychiatric Residency training: General/ Adult Psychiatry training,Sub-speciality training in Child and Adolescent Mental Health (CAMH).',
    },
  ];
  const research = [
    {
      name:
        'The Consultants and Resident Doctors in the department carry out collaborative research within the department and also with staff from other departments in the hospital and neighbouring institutions such as Federal Neuro-Psychiatric Hospital, Yaba, Lagos.',
    },
  ];

  return (
    <Layout pageTitle='Department of Psychiatry | LUTH'>
      <NavOne />
      <PageHeader title='Department of Psychiatry' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Psychiatry
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        Psychiatry is a full-fledged Department under the
                        College of Medicine, University of Lagos (CMUL) and
                        Lagos University Teaching Hospital (LUTH). The primary
                        roles of the Department include clinical services,
                        training of Medical Students, Resident Doctors as well
                        as other Health Professionals and Research.
                        <br /> <br/>
                        The following are the clinical sub-specialities in the
                        department <br/>• General/ Adult Psychiatry <br/>• Child and
                        Adolescent Mental Health (CAMH) <br/>• Consultation-Liaison
                        Psychiatry <br/>• Old Age Psychiatry (Psycho-geriatrics) <br/>•
                        Addiction Psychiatry
                        <br /> <br/>
                        The Department is primarily domiciled at CMUL/ LUTH
                        Annexe; No. 6, Harvey Road, Yaba; and other locations
                        with respective services rendered as follows:
                        <br />
                        1. <b>Harvey Road, Yaba facilities</b> The Department of
                        Psychiatry, LUTH is domiciled at LUTH Annexe; No. 6,
                        Harvey Road, Yaba. Here, the department runs out-patient
                        clinics on Tuesdays and Thursdays; as well as gives
                        lectures and clinical teaching/ demonstration to Medical
                        students on psychiatric clinical posting.
                        <br />
                        2. <b>LUTH, Idi-Araba facilities</b> The department also
                        runs clinical services at Idi-Araba as follows: <br />•
                        Ward E 1 where acutely ill patients are admitted for
                        in-patients’ care. Ward rounds are carried out on
                        Tuesdays, Thursdays and Fridays. <br />• Out-patient
                        clinics include: -General/Adult psychiatric clinic on
                        Mondays - Child/Adolescent clinic on Mondays
                        -Consultation-liaison clinics in Cardiology and Oncology
                        clinics.
                        <br />
                        3.{' '}
                        <b>
                          LUTH Comprehensive Health Centre, Pakoto, Ifo, Ogun
                          State
                        </b>
                        . The department carries out her Community Mental Health
                        care services here.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <h2 className='course-details__title'>Clinical Services</h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {clinical.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Training
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {training.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Research
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {research.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={joseph} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof Joseph D. ADEYEMI </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            (MBBS, MSc (Psych), FM (Psych)
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General & Adult Psychiatry; Consultation-Liaison
                            Psychiatry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={aina} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof Olatunji F. AINA </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FWACP
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General & Adult Psychiatry; Consultation-Liaison
                            Psychiatry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={erinfola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Adebayo R. ERINFOLAMI </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc, FMCPsych
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General & Adult Psychiatry; Addiction Psychiatry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oshodi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Yewande O.OSHODI </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, Mphil, Cert C&A, FMCPsych
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General Psychiatry, Child & Adolescent Psychiatry;
                            Women Mental Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fatiregun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Olamijulo A. FATIREGUN </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MSc, FMCPsych
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Hospital Consultant Special Grade 2
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General & Adult Psychiatry; Old Age Psychiatry
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={sodimu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Kehinde A. SODIMU </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB, FMCPsych
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Hospital Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>
                            General & Adult Psychiatry; Consultation-Liaison
                            Psychiatry.
                          </p>
                        </div>
                      </div>
                      <h2 className='course-details__title'>
                        Clinical Psychologists
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={umeh} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Charles S. UMEH </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            BSc, MSc, PhD
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Hospital Consultant
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={tajudeen} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Tajudeen F. SULEIMAN </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            BSc, MSc, PGDE, PhD
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Deputy Director
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okeowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Olumide O. OKEOWO </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            BSc, MSc
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Clinical Psychologist I
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={juliet} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Juliet OTTOH </h3>
                          <p>
                            <span className='detail-head'>Qualification: </span>{' '}
                            BSc, MSc
                            <br />
                            <span className='detail-head'>Designation: </span>
                            Clinical Psychologist I
                            <br />
                          </p>
                        </div>
                      </div>
                      <h2 className='course-details__title'>
                        Administrative Staff
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mary O ADEPAROYE </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Chief Clerical Officer
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Temitayo R. ABUDU </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            OND
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Assistant Data Processing Officer
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img height={206} src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Taofikat A. AJAYI </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            WAEC
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Senior Clerical Officer
                            <br />
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Gafar O. MOLADE </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            WAEC
                            <br />
                            <span className='detail-head'>Designation:</span>
                            Clerical Officer
                            <br />
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:psychiatry@luth.gov.ng'>                        
                        psychiatry@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Psychiatry;
