import React, { Component } from 'react';

class Tab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = 'tab-list-item';

    if (activeTab === label) {
      className += ' tab-list-active';
    }

    return (
      // <li className={className} onClick={onClick}>
      <li>
        {/* {label} */}
        <a className={className} onClick={onClick} role='tab' data-toggle='tab'>
          {label}
        </a>
      </li>
    );
  }
}

export default Tab;
